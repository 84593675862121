import classNames from 'classnames';
import * as React from 'react';
import {
  customCssClasses,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import semanticClassNames from '../Page.semanticClassNames';
import { PageProps } from '../Page.types';

const Page: React.FC<PageProps> = props => {
  const {
    id,
    className,
    customClassNames = [],
    skin: PageClass,
    pageDidMount,
    onClick = () => {},
    onDblClick = () => {},
    onMouseEnter,
    onMouseLeave,
    children,
  } = props;
  return (
    <PageClass
      id={id}
      className={classNames(
        className,
        customCssClasses(semanticClassNames.root, ...customClassNames),
      )}
      {...getDataAttributes(props)}
      pageDidMount={pageDidMount}
      onClick={onClick}
      onDblClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children as () => React.ReactNode}
    </PageClass>
  );
};

export default Page;
